import React from "react"
import { Collapse, Input, Form, Icon, Button, Spin, InputNumber, Modal, Row, Col } from 'antd'
import { Helmet } from "react-helmet"
import SiteLayout from "../components/SiteLayout";
import Start from "../components/Sections/Start";
const Panel = Collapse.Panel;

// @ts-ignore
import styles from './komigang.module.scss';

/*

*/


export default ({ children }) => (
    <SiteLayout>
        <Helmet>
            <meta charSet="utf-8" />
            <title>Oss | Hjelp</title>
            <script>{`
                    {
            window.intercomSettings = {
                app_id: "wpsqtjpa"
            };
            }`}
            </script>
            <script>{`
                    {
            (function(){var w=window;var ic=w.Intercom;if(typeof ic==="function"){ic('reattach_activator');ic('update',intercomSettings);}else{var d=document;var i=function(){i.c(arguments)};i.q=[];i.c=function(args){i.q.push(args)};w.Intercom=i;function l(){var s=d.createElement('script');s.type='text/javascript';s.async=true;s.src='https://widget.intercom.io/widget/wpsqtjpa';var x=d.getElementsByTagName('script')[0];x.parentNode.insertBefore(s,x);}if(w.attachEvent){w.attachEvent('onload',l);}else{w.addEventListener('load',l,false);}}})()
            }`}
            </script>
        </Helmet>
        <div className={styles.smallcontainer}>

            <h1>KOM I GANG</h1>
           
                                    
            <div className={styles.introtext}>Har du mottatt en Oss-brikke og trenger hjelp med å komme i gang? Klikk på det alternativet nedenfor som passer deg, så hjelper vi deg videre 😊 
            </div>
            <Row style={{ marginLeft: "auto", marginRight: "auto"}} type="flex" justify="space-around" align="top">
                    <Col xs={24} sm={12} style={{ }}>
                    <button onClick={showModalOpen} className={styles.overlay}>Jeg har ikke bedt min nettleverandør om å åpne HAN-porten på strømmåleren min.</button>
                   
                    </Col>
                    <Col xs={24} sm={12} style={{ }}>
                    <button  onClick={showModalClosed}  className={styles.overlay}>Jeg har bedt min nettleverandør om å åpne HAN-porten på strømmåleren min.</button>
                       
                        
                    
                    </Col>
                    </Row>
                   
        </div>
       
    </SiteLayout>
)
function showModalOpen() {
    Modal.info({
        title: "",
        content: (
            <div>
                <h3>Jeg har ikke bedt min nettleverandør om å åpne HAN-porten på strømmåleren min.</h3>
                <p> For at Oss-brikken skal fungere må du kontakte din nettleverandør (ikke strømleverandør) og be dem om å åpne HAN-porten din. Hos de aller fleste nettleverandører kan dette gjøres via min side. Er ikke dette tilfellet hos deg, kontakt din nettleverandør via e-post eller telefon. </p>
               
            </div>
        ),
        onOk() {},
        width: 650
    });
}
function showModalClosed() {
    Modal.info({
        title: "",
        content: (
            <div>
                <h3>Jeg har bedt min nettleverandør om å åpne HAN-porten på strømmåleren min.</h3>
                <p>Klikk på alternativet nedenfor som passer deg.  </p>
                <Collapse bordered={false}>
                <Panel header="Jeg har koblet til Oss-brikken i HAN-porten" key="1" className={styles.panel}>
                        <div className={styles.text}>
                           
                        Oss-brikken lades og oppdateres automatisk, dette tar ca. 2-3 timer. Vi anbefaler deg å nyte kvelden og få en god natts søvn, før du tar en titt i appen på morgenkvisten ;)
                    </div>
                </Panel>
                <Panel header="Oss-brikken har vært koblet til et døgn, men jeg får ingen data opp i appen" key="2" className={styles.panel}>
                        <div className={styles.text}>
                        Ta en titt på hvordan Oss-brikken blinker. Blinkene viser status på brikken. 
                        <ul>
                                <li>Ingen blink - Jeg har Aidon måler
                                    <ul>
                                        <li>
                                        HAN-porten er ikke aktivert
                                        </li>
                                    </ul>
                                </li>
								<li>Tre (3) røde blink
                                    <ul>
                                        <li>
                                            HAN-porten er ikke aktivert
                                        </li>
                                    </ul>
                                </li>
								<li>Ett (1) hvitt og to (2) røde blink
                                    <ul>
                                        <li>Du har akkurat plugget Oss-brikken til HAN-porten og den kobler seg opp til Oss-plattformen, tar ca. 2-3 timer</li>
                                        <li>
                                        Ingen dekning for Oss-brikken<i> - Prøv å flytt brikken ut av sikringsskapet</i>
                                        </li>
                                    </ul>
                                </li>
                                <li>En til Fire (1-4) hvite blink hvert femte sekund 
                                    <ul>
                                        <li>
                                        Oss-brikken lader
                                        </li>
                                    </ul>
                                </li>
                                <li>En til Fire (1-4) hvite blink hvert tiende sekund
                                    <ul>
                                        <li>
                                        Alt ok med Oss-brikken
                                        <ul>
                                            <li><b>Frekvensen på den hvite blinkingen viser hvor god dekning Oss-brikken har.</b></li>
                                            <li>Ett (1) hvitt blink hvert tiende sekund indikerer dårlig dekning</li>
                                            <li>Fire (4) hvite blink hvert tiende sekund indikerer god dekning.</li>
                                        </ul>
                                        </li>
                                    </ul>
                                </li>
                                <li>Ett (1) hvitt blink hvert andre sekund
                                    <ul>
                                        <li>
                                        Laster ned og oppdaterer firmware på Oss-brikken
                                        </li>
                                    </ul>
                                </li>
                                
                            </ul>
                            

                        </div>
                    </Panel>
                    <Panel header="Jeg har ikke koblet til Oss-brikken i HAN-porten." key="3" className={styles.panel}>
                        <div className={styles.text}>
                        Du må koble Oss-brikken inn HAN-porten. Den finner du på strømmåleren, passer bare ett sted. Etter du har koblet den til vil Oss-brikken lades og oppdateres automatisk, dette tar ca. 2-3 timer.



                        </div>
                    </Panel>
                 
                </Collapse>
            </div>
        ),
        onOk() {},
        width: 650
    });
}